import { ApolloProvider } from '@apollo/client'
import ErrorBoundary from '@comparacar/components/src/atoms/ErrorBoundary'
import PageProvider from '@comparacar/components/src/atoms/PageProvider'
import PageTransitionWrapper from '@comparacar/components/src/atoms/PageTransitionWrapper'
import client from '@comparacar/lib/src/api/apollo'
import useSystemColorScheme from '@comparacar/lib/src/hooks/useSystemColorScheme'
import DEFAULTSEOPROPS from '@comparacar/lib/src/next-seo.config'
import composeSeoUrl from '@comparacar/lib/src/utils/composeSeoUrl'
import { info } from '@comparacar/lib/src/utils/JsonLd'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import merge from 'lodash.merge'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { CorporateContactJsonLd, NextSeo, ProductJsonLd, FAQPageJsonLd } from 'next-seo'
import NextNProgress from 'nextjs-progressbar'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { RecoilRoot } from 'recoil'

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout
}

function MyApp(props: MyAppProps) {
  const colorScheme = useSystemColorScheme()
  const contactInfo = info()

  const { Component, pageProps } = props
  const getLayout = Component.getLayout ?? (page => page)

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HOTJAR_ID) hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID), 6)
  }, [])

  return (
    <AppCacheProvider {...props}>
      <>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          {colorScheme === 'dark' ? (
            <>
              <link rel="apple-touch-icon" type="image/svg+xml" sizes="180x180" href={`/static/favicon-white.png`} />
              <link rel="icon" sizes="32x32" type="image/svg+xml" href={`/static/favicon-white.png`} />
              <link rel="icon" sizes="16x16" type="image/svg+xml" href={`/static/favicon-white.png`} />
              <link rel="mask-icon" type="image/svg+xml" href={`/static/favicon-white.png`} />
              <link rel="shortcut icon" type="image/svg+xml" href={`/static/favicon-white.png`} />
            </>
          ) : (
            <>
              <link rel="apple-touch-icon" type="image/svg+xml" sizes="180x180" href={`/static/favicon.png`} />
              <link rel="icon" sizes="32x32" type="image/svg+xml" href={`/static/favicon.png`} />
              <link rel="icon" sizes="16x16" type="image/svg+xml" href={`/static/favicon.png`} />
              <link rel="mask-icon" type="image/svg+xml" href={`/static/favicon.png`} />
              <link rel="shortcut icon" type="image/svg+xml" href={`/static/favicon.png`} />
            </>
          )}
          <title>ComparaCar</title>
        </Head>
        <div className="notranslate">
          <PageProvider>
            <RecoilRoot>
              <PageTransitionWrapper>
                <ApolloProvider client={client}>
                  <NextNProgress color="#29D" startPosition={0.3} stopDelayMs={200} height={3} />
                  {getLayout(
                    <ErrorBoundary>
                      <Component {...pageProps} />
                    </ErrorBoundary>
                  )}
                </ApolloProvider>
              </PageTransitionWrapper>
            </RecoilRoot>
          </PageProvider>
        </div>
        {pageProps.seo?.NextSeo ? (
          <NextSeo
            {...merge(DEFAULTSEOPROPS(composeSeoUrl('DESKTOP')), pageProps.seo.NextSeo)}
            mobileAlternate={{
              href: composeSeoUrl('MOBILE'),
              media: 'only screen and (max-width: 1024px)'
            }}
          />
        ) : (
          <NextSeo
            {...{
              ...DEFAULTSEOPROPS,
              mobileAlternate: {
                href: composeSeoUrl('MOBILE'),
                media: 'only screen and (max-width: 1024px)'
              }
            }}
          />
        )}
        {pageProps.seo?.ProductJsonLd && <ProductJsonLd {...pageProps.seo.ProductJsonLd} />}
        {pageProps.seo?.FAQPageJsonLd && <FAQPageJsonLd {...pageProps.seo.FAQPageJsonLd} />}
        <CorporateContactJsonLd
          name={contactInfo.name}
          url={contactInfo.url}
          contactPoint={contactInfo.contactPoint}
          logo={contactInfo.logo}
          sameAs={contactInfo.sameAs}
        />
      </>
    </AppCacheProvider>
  )
}

export default MyApp
